import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Main } from './Main';

function Routes() {
  return (
    <Switch>
        <Route exact path='/'>{Main}</Route>
        <Route exact path='*'>
            page not found go to <a href={`http://formology.garden`}>http://formology.garden</a>
        </Route>
    </Switch>
  );
}

export default Routes;