import React from 'react';
import * as CSSModules from 'react-css-modules';

import * as styles from './Main.css';

import { useScroll } from './useScroll';
import { Carousell } from './Carousell';
import { Button } from './Button';
import { Menu } from './Menu';
import { getContent } from './content/content.js';

export const Template = (props) => {

  const { scrollDirection } = useScroll();
  const top = (scrollDirection === undefined || scrollDirection === 'up' ) ? 0 : -75;
  const backgroundColor = (scrollDirection !== undefined) ? (scrollDirection === 'up' ) ? 'rgba(0, 0, 0, 0)' : 'transparent' : 'transparent';

  const navbarStyle = {top: top, backgroundColor: backgroundColor}

  const content = getContent()[props.name];
  const text = "Choose your space";

  return (
    <div className="container">
      <Menu navbarStyle={navbarStyle}/>
      <div className="main">
        <Carousell background={content.background.images} text={content.text} infiniteLoop={content.background.infiniteLoop}/>
      </div>
      <div className="sub-text">{text}</div>
      <div className="buttons">
          <a className="button-wrapper" href={content.buttons.left.link}>
            <Button description={content.buttons.left.text} background={content.buttons.left.picture}/>
          </a>
          <a className="button-wrapper" href={content.buttons.middle.link}>
            <Button description={content.buttons.middle.text} background={content.buttons.middle.picture}/>
          </a>
          <a className="button-wrapper" href={content.buttons.right.link}>
            <Button description={content.buttons.right.text} background={content.buttons.right.picture}/>
          </a>
      </div>
    </div>
  );
}

export default CSSModules(Template, styles)
