import React from 'react';
import './App.css';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
  <BrowserRouter basename="/react_homepage">
    <Routes />
  </BrowserRouter>
  );
}

export default App;