import React from 'react';
import * as styles from './Button.css';
import * as CSSModules from 'react-css-modules';

export const Button = (props) => {
  return (
    <div>
      <div className="button-image" style={{backgroundImage: `url(${props.background})`}}></div>
      <div className="button-text-wrapper">
        <div className="button-description">{props.description}</div>
      </div>
    </div>
  );
}

export default CSSModules(Button, styles)
