import React from 'react';
import * as CSSModules from 'react-css-modules';
import CookieConsent from "react-cookie-consent";

import { Template } from './Template';
import { getContent } from './content/content.js';

export function Main() {

  const content = getContent();
  const cookie = localStorage.getItem('formology.garden');

  return (
    <>
        <Template name="main"/>
        { !cookie ? <CookieConsent style={{ background: 'rgba(0,0,0,0.75)' }}
            enableDeclineButton={true}
            hideOnAccept={true}
            onAccept={() => {
                        localStorage.setItem('formology.garden', true);
                     }}
        >{content.gdpr}</CookieConsent> : null }
    </>
  );
}

export default CSSModules(Main, null)
