import React from 'react';
import * as CSSModules from 'react-css-modules';
import * as styles from './Menu.css';

export const Menu = (props) => {
  return (
      <div id="navbar" className="menu-navbar" style={props.navbarStyle}>
        <a href="https://formology.design/" className="menu-left">
        </a>
        <div className="menu-right">
          <div className="menu-options">
            <a href="https://info.formology.design/blog"><div className="menu-option">Blog</div></a>  <div className="menu-option-separator"> | </div>
            <a href="https://info.formology.design/faqs"><div className="menu-option">FAQS</div></a>  <div className="menu-option-separator"> | </div>
            <a href="https://info.formology.design/about"><div className="menu-option">About</div></a> <div className="menu-option-separator"> | </div>
            <a href="https://info.formology.design/contact"><div className="menu-option">Contact</div></a>
          </div>
        </div>
      </div>
  );
}

export default CSSModules(Menu, styles)
