import React, { useEffect, useState } from 'react';
import * as CSSModules from 'react-css-modules';

export const Carousell = (props) => {
    const [count, setCount] = useState(0);

    //manage time interval
    useEffect(() => {
        const max = Math.max(props.background.length, props.text.length)
        const id =  setInterval(() => {
                        setCount(count => (count + 1) % max);
                    }, 3000)
        if (count === (max-1) && !props.infiniteLoop) {
            clearInterval(id);
        }
        return () => clearInterval(id);
    }, [count])

    const backgroundIndex = Math.min(count,props.background.length-1);
    const textIndex = Math.min(count,props.text.length-1);

    //manage loading images
    const src = props.background[backgroundIndex];
    const srcText = props.text[textIndex];

    const placeholder = props.background[backgroundIndex];
    const placeholderText = props.text[textIndex];

    const [loading, setLoading] = useState(true);
    const [currentSrc, updateSrc] = useState(placeholder);
    const [currentTextSrc, updateTextSrc] = useState(placeholderText);

    useEffect(() => {
        // start loading original image
        const imageToLoad = new Image();
        imageToLoad.src = src;
        imageToLoad.onload = () => {
          // When image is loaded replace the src and set loading to false
          setLoading(false);

          updateSrc(src);
          updateTextSrc(srcText);
        }
    }, [src,srcText])

    return (
        <>
            <div className="background">
                <div className="background-image" style={{backgroundImage: `url(${currentSrc})`}}></div>
            </div>
            <div className="text">
                <div className="logo">
                    <div className="text-logo">{currentTextSrc.logo}</div>
                    <div className="sub-logo">{currentTextSrc.subtext}</div>
                </div>
            </div>
        </>
    );
}

export default CSSModules(Carousell, null)
