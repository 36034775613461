import Order from '../images/order.JPG';

const MainBackground1 = require('../images/main/MAIN01.jpg').default;
const MainBackground2 = require('../images/main/MAIN02.jpg').default;
const MainBackground3 = require('../images/main/MAIN03.jpg').default;
const MainBackground4 = require('../images/main/MAIN04.jpg').default;
const MainBackground5 = require('../images/main/MAIN05.jpg').default;
const MainBackground6 = require('../images/main/MAIN06.jpg').default;
const MainBackground7 = require('../images/main/MAIN07.jpg').default;

const MainStudio = require('../images/main/Studio.png').default;
const MainCabin = require('../images/main/Cabin.png').default;
const MainLodge = require('../images/main/Lodge.png').default;

export function getContent(): any {
    const content = {
        "gdpr" : "This website uses cookies to enhance the user experience.",
        "main" : {
            "background": {
                "images" : [MainBackground1,MainBackground2,MainBackground3,MainBackground4,MainBackground5,MainBackground6],
                "infiniteLoop" : false
            },
            "text" : [{
                "logo": "Upgrade your Garden",
                "subtext": "",
                "title": "",
                "description": ""
            },
            {
                "logo": "Upgrade your Garden",
                "subtext": "Make it Work for You",
                "title": "",
                "description": ""
            },
            {
                "logo": "Upgrade your Garden",
                "subtext": "Make it Playful",
                "title": "",
                "description": ""
            },
            {
               "logo": "Upgrade your Garden",
               "subtext": "Make it a Place to Grow",
               "title": "",
               "description": ""
            },
            {
               "logo": "Upgrade your Garden",
               "subtext": "Make it Care",
               "title": "",
               "description": ""
            },
            {
                "logo": "Upgrade your Garden",
                "subtext": "Make it Formology",
                "title": "",
                "description": ""
            },
            {
                "logo": "Formology",
                "subtext": "Spaces for life",
                "title": "",
                "description": ""
            }],
              "buttons" : {
                  "left" : {
                    "text" : "Starting from £9,970",
                    "link" : "http://info.formology.garden/studio",
                    "picture" : MainStudio
                  },
                  "middle" : {
                    "text" : "Starting from £10,970",
                    "link" : "http://info.formology.garden/cabin",
                    "picture" : MainCabin
                  },
                  "right" : {
                    "text" : "Starting from £21,3200",
                    "link" : "http://info.formology.garden/lodge",
                    "picture" : MainLodge
                  }
              }
        }
    };
    return content;
}


